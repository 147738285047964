import React from "react";
import Section4 from "../Home/Section4/Section4";

const Places = () => {
  return (
    <>
      <div>
        <Section4 />
      </div>
      <div>
        
      </div>
    </>
  );
};

export default Places;
