import React from 'react'
import Section5 from '../Home/Section5/Section5'

const Contact = () => {
  return (
    <div>
      <Section5/>
    </div>
  )
}

export default Contact
